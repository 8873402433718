import { StateCode, StateName } from "../location.types";
import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProvider,
  ServiceProviderLendingPartnerType,
  ServiceProviderStatusType,
  ServiceProviderType
} from "../rolodex";

export const getServiceProviderTemplate = (
  type: ServiceProviderType
): RolodexServiceProvider => {
  if (type === ServiceProviderType.Company) {
    return getCompanyProviderTemplate();
  }
  if (type === ServiceProviderType.Branch) {
    return getBranchProviderTemplate();
  }
  if (type === ServiceProviderType.Representative) {
    return getRepProviderTemplate();
  }
  throw new Error("getServiceProviderTemplate type not supported");
};

const getCompanyProviderTemplate = (): CompanyServiceProvider => {
  return {
    type: ServiceProviderType.Company,
    id: "",
    createdAt: "",
    domainConfigurationId: "",
    index: "",
    modifiedAt: "",
    name: "",
    partyIds: [],
    status: "" as ServiceProviderStatusType,
    experienceLevel: undefined,
    notes: "",
    ein: ""
  };
};
const getBranchProviderTemplate = (): BranchServiceProvider => {
  return {
    type: ServiceProviderType.Branch,
    id: "",
    createdAt: "",
    domainConfigurationId: "",
    companyId: "",
    index: "",
    modifiedAt: "",
    name: "",
    partyIds: [],
    status: "" as ServiceProviderStatusType,
    coverageArea: "",
    ein: "",
    email: "",
    experienceLevel: undefined,
    lendingPartnerType: "" as ServiceProviderLendingPartnerType,
    nmlsLicenseNumber: "",
    notes: "",
    numberOfEmployees: undefined,
    phoneNumber: "",
    wireAccountNumber: "",
    wireNameOnAccount: "",
    wireRoutingNumber: ""
  };
};
const getRepProviderTemplate = (): RepresentativeServiceProvider => {
  return {
    type: ServiceProviderType.Representative,
    id: "",
    createdAt: "",
    domainConfigurationId: "",
    companyId: "",
    index: "",
    modifiedAt: "",
    partyIds: [],
    firstName: "",
    lastName: "",
    businessRegistrationStates: [],
    status: "" as ServiceProviderStatusType,
    birthDate: "",
    driversLicenseNumber: "",
    experienceLevel: undefined,
    licenseNumber: "",
    middleName: "",
    nmlsLicenseNumber: "",
    notes: "",
    phoneNumber: "",
    skipped: undefined,
    email: "",
    Address: {
      AddressUnitIdentifier: "",
      CityName: "",
      PostalCode: "",
      StateName: "" as StateName,
      AddressLineText: "",
      CountyName: "",
      StateCode: "" as StateCode
    }
  };
};
