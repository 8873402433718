export enum LenderIdentifierType {
  LimaOneCapital = "Lima One Capital, LLC",
  InvestorLendingUSA = "Investor Lending USA",
  REIServicing = "The REI Lender, LLC"
}

export enum ChannelType {
  Broker = "Broker",
  BusinessDevelopment = "Business Development",
  InsideSales = "Inside Sales",
  Correspondant = "Correspondant",
  Commercial = "Commercial",
  Wholesale = "Wholesale",
  LenderFinance = "Lender Finance",
  POD = "POD"
}

export enum RealEstateProgramType {
  ShortTermFinance = "Short Term Finance",
  FixNFlip = "FixNFlip",
  Rental = "Rental",
  Multifamily = "Multifamily"
}

export enum LoanProductType {
  N51ARM = "5/1 ARM",
  N5IO25ARM = "5 IO - 25ARM",
  N101ARM = "10/1 ARM",
  Fixed = "Fixed"
}

export enum LoanProgramType {
  Rental30 = "Rental30",
  Rental30Premier = "Rental30 Premier",
  FixNFlip = "FixNFlip",
  NewConstruction = "New Construction",
  BridgePlus = "BridgePlus"
}

export enum RecourseType {
  FullRecourse = "Full Recourse",
  NonRecourse = "Non-Recourse"
}

export enum AmortizationType {
  Fixed = "Fixed",
  Adjustable = "Adjustable",
  FixedToAdjustable = "Fixed To Adjustable"
}

export enum ExecutionType {
  NotApplicable = "Not Applicable",
  CommitmentFunded = "Commitment Funded"
}

export enum LoanPaymentType {
  PI = "Principal & Interest",
  IO = "Interest Only",
  IOConvertedToPI = "IO Converted To P&I"
}

export enum InterestAccrualMethodType {
  N30360 = "30/360"
}

export enum PrepaymentPenaltyType {
  NoPrepayment = "No Prepayment",
  YieldMaintenance = "Yield Maintenance",
  DeferredOriginationFee = "Deferred Origination Fee",
  N321 = "3,2,1",
  N33321 = "3,3,3,2,1",
  N5544321 = "5,5,4,4,3,2,1",
  N54321 = "5,4,3,2,1"
}

export enum AchExemptionStatusType {
  Exempt = "Exempt",
  NonExempt = "Non-exempt"
}

export enum IndexSourceType {
  N6MonthSOFR = "6 Month SOFR",
  N30DaySOFR = "30 Day SOFR"
}

export enum CapitalStructureType {
  N525 = "5,2,5"
}

export enum ARMRepriceRoundingRatePercentType {
  N0125 = "0.125%"
}

export enum ARMRepriceRoundingMethodType {
  Up = "Up"
}

export enum ARMRepriceLookbackType {
  N45Days = "45 Days"
}

export enum ARMRepriceFrequencyType {
  Annually = "Annually",
  Monthly = "Monthly",
  SemiAnnually = "Semi-Annually"
}

export enum WarehouseBankType {
  AmerisBank = "Ameris Bank",
  BancOfCalifornia = "Banc Of California",
  GeorgiaBankingCompany = "Georgia Banking Company",
  LimaOneCapital = "Lima One Capital",
  SilvergateBank = "Silvergate Bank",
  Flagstar = "Flagstar",
  DeutscheBank = "Deutsche Bank"
}

export enum WarehouseBankStatusType {
  Funded = "Funded",
  Imported = "Imported",
  Uploaded = "Uploaded",
  WireRequested = "Wire Requested",
  PendingWireRequest = "Pending Wire Request"
}

export enum FundingShieldStatusType {
  NotStarted = "Not Started",
  Ordered = "Ordered",
  InReview = "In Review",
  RevisionsRequested = "Revisions Requested",
  Complete = "Complete"
}

export enum CollateralPackageStatusType {
  Pending = "Pending",
  Received = "Received"
}

export enum ShippingCompanyType {
  USPS = "USPS",
  FedEx = "FedEx",
  UPS = "UPS"
}

export enum PropertyModelType {
  SingleProperty = "Single Property",
  Portfolio = "Portfolio"
}

export enum PropertyManagerType {
  SelfManaged = "Self Managed",
  ProfessionalManagementCompany = "Professional Management Company"
}

export enum DealMilestoneType {
  PreSubmissionDeal = "Pre-Submission Deal",
  NewDeal = "New Deal",
  InitialDealReview = "Initial Deal Review",
  Processing = "Processing",
  FinalDealReview = "Final Deal Review",
  FinalDealReviewApproved = "Final Deal Review Approved",
  FinalQuoteIssued = "Final Quote Issued",
  FinalQuoteAccepted = "Final Quote Accepted",
  FinalClosingUpdatesRequested = "Final Closing Updates Requested",
  ClearToClose = "Clear to Close",
  LoanPackageSent = "Loan Package Sent",
  LoanPackageReceived = "Loan Package Received",
  Funded = "Funded",
  CloseOfEscrow = "Close of Escrow",
  FileComplete = "File Complete",
  Suspended = "Suspended",
  DeadDeal = "Dead Deal"
}

export enum DeadDealGenericReasonType {
  IncompleteSubmission = "Incomplete Submission",
  DeniedInUnderwriting = "Denied in Underwriting",
  WithdrawnByApplicant = "Withdrawn by Applicant",
  SubmittedByMistake = "Submitted by Mistake"
}

export enum DeadDealSpecificReasonForIncompleteSubmissionType {
  AssetDocumentation = "Asset Documentation",
  EntityDocumentation = "Entity Documentation",
  SREODocumentationForQuotedTier = "SREO Documentation for Quoted Tier",
  SponsorApplication = "Sponsor Application",
  PSA = "PSA",
  Budget = "Budget",
  Plans = "Plans",
  InaccurateQuote = "Inaccurate Quote"
}

export enum DeadDealSpecificReasonForDeniedInUnderwritingType {
  BorrowerEntityStructure = "Borrower - Entity Structure",
  BorrowerSponsorFailedBackgroundCheck = "Borrower - Sponsor Failed Background Check",
  BorrowerExperience = "Borrower - Experience",
  BorrowerFICO = "Borrower - FICO",
  BorrowerSponsorPerformanceOnL1CLoans = "Borrower - Sponsor Performance on L1C Loans",
  BorrowerInsufficientAssets = "Borrower - Insufficient Assets",
  BorrowerAtLOCExposureLimit = "Borrower - at LOC Exposure Limit",
  BudgetContractorNotApproved = "Budget - Contractor Not Approved",
  BudgetIneligibleScopeOfWork = "Budget - Ineligible Scope of Work",
  BudgetIneligibleScopeOfWorkMidConstruction = "Budget - Ineligible Scope of Work - Mid-Construction",
  BudgetConstructionNotCompleteForPerm = "Budget - Construction Not Complete (for Perm)",
  PropertyUnwarrantableCondo = "Property - Unwarrantable Condo",
  PropertyIneligibleCharacteristicsLotZoningValueTypeEnvironmental = "Property - Ineligible Characteristics (Lot, Zoning, Value, Type, Environmental)",
  PropertyIneligibleValue = "Property - Ineligible Value",
  PropertyCashFlow = "Property - Cash Flow",
  PropertyUnableToMeetClosingDate = "Property - Unable to meet Closing Date",
  PropertyLiens = "Property - Liens",
  PropertyPendingLitigation = "Property - Pending Litigation",
  TitleIneligibleDealStructure = "Title - Ineligible Deal Structure",
  ProhibitedPartnerListUnverifiableInformation = "Prohibited Partner List - Unverifiable Information"
}

export enum DeadDealSpecificReasonForWithdrawnByApplicantType {
  CompetitiveOffer = "Competitive Offer",
  DidNotPayAppraisalFee = "Didn't Pay Appraisal Fee",
  AppraisalTurnTime = "Appraisal Turn Time",
  PropertyDueDiligenceRequestDenial = "Property Due Diligence Request Denial",
  SellerDidNotExtendCOE = "Seller did not extend COE",
  UpdatedQuoteIssuedBudgetCostAdjustment = "Updated Quote Issued - Budget Cost Adjustment",
  UpdatedQuoteIssuedOnAppraisalReceipt = "Updated Quote Issued on Appraisal Receipt",
  DisputedTermsOfLoanDocuments = "Disputed Terms of Loan Documents"
}

export enum DeadDealSpecificReasonForSubmittedByMistakeType {
  SalesforceUserError = "Salesforce - User Error", // Salesforce User submitted the deal by mistake
  SalesforceAPIError = "Salesforce - Integration Error", // Salesforce is using elphi Create Deal api incorrectly
  ElphiUserError = "Elphi - User Error", // Elphi User submitted the deal by mistake
  ElphiAPIError = "Elphi - System Error", // Elphi is using its own Create Deal api incorrectly
  LenderFinanceUserError = "Lender Finance - User Error", // Lender Finance User submitted the deal by mistake
  LenderFinanceAPIError = "Lender Finance - Integration Error", // Lender Finance is using elphi Create Deal api incorrectly
  Other = "Other" // To not block users; monitor usage; identify patterns to justify new enums
}

export enum SuspendedGenericReasonType {
  DelayInProcessing = "Delay in Processing",
  BorrowerSuspended = "Borrower Suspended"
}

export enum SuspendedSpecificReasonForDelayInProcessingType {
  ConstructionNotCompleteForPerm = "Construction Not Complete (for Perm)",
  AppraisalPaymentDelay = "Appraisal Payment Delay",
  TitleIssuesUnclearTitle = "Title Issues/Unclear Title"
}

export enum SuspendedSpecificReasonForBorrowerSuspendedType {
  SellerRelatedIssues = "Seller-related Issues",
  LoanDocsDisputed = "Loan Docs - Disputed",
  BorrowerNegotiatingLoanTerms = "Borrower Negotiating Loan Terms",
  AppraisalIssues = "Appraisal Issues",
  BorrowerAtLOCExposureLimit = "Borrower at LOC Exposure Limit",
  NoResponse = "No Response"
}

export enum PaymentDueDayType {
  N10 = "10"
}

export enum TitleVestingType {
  Borrower = "Borrower",
  TransferringTitleInRequirements = "Transferring Title In Requirements"
}

export enum UnderwriterApprovalStatusType {
  NotApproved = "Not Approved",
  Approved = "Approved"
}

export enum DealOperationsDepartmentType {
  EastCoastOperations = "East Coast Operations",
  WestCoastOperations = "West Coast Operations",
  StrategicAccounts = "Strategic Accounts",
  LenderFinanceOperations = "Lender Finance Operations"
}

export enum PricingEngineExceptionStatusType {
  TBD = "TBD"
}
export enum LoanPurposeType {
  Purchase = "Purchase",
  RateTermRefinance = "Rate Term Refinance",
  CashOutRefinance = "Cash Out Refinance",
  PurchaseAndRateTermRefinance = "Purchase and Rate Term Refinance",
  PurchaseAndCashOutRefinance = "Purchase and Cash Out Refinance"
}

export enum LenderEntityType {
  GeneralPartnership = "General Partnership",
  LimitedLiabilityCompany = "Limited Liability Company",
  LimitedLiabilityPartnership = "Limited Liability Partnership",
  LimitedPartnership = "Limited Partnership",
  LimitedLiabilityLimitedPartnership = "Limited Liability Limited Partnership",
  CCorp = "C-Corp",
  SCorp = "S-Corp",
  LandTrust = "Land Trust",
  Corporation = "Corporation"
}

export enum DebtStatusType {
  Purchase = "Purchase",
  PayoffExistingMortgage = "Payoff Existing Mortgage",
  FreeAndClear = "Free & Clear"
}
export enum LoanTermPeriodMonthCount {
  N13 = "13",
  N19 = "19",
  N24 = "24",
  N60 = "60",
  N120 = "120",
  N360 = "360"
}

export enum ExceptionPolicy {
  V1_1 = "1.1",
  V1_2 = "1.2",
  V1_3 = "1.3",
  V1_4 = "1.4",
  V1_4_1 = "1.4.1",
  V1_4_2 = "1.4.2",
  V1_5 = "1.5",
  V1_6 = "1.6",
  V1_6_1 = "1.6.1",
  V1_6_2 = "1.6.2",
  V1_7 = "1.7",
  V1_8 = "1.8",
  V1_8_1 = "1.8.1",
  V1_9 = "1.9",
  V1_10 = "1.10",
  V1_11 = "1.11",
  V1_12 = "1.12",
  V2_1 = "2.1",
  V2_2 = "2.2",
  V2_3 = "2.3",
  V2_4 = "2.4",
  V2_4_1 = "2.4.1",
  V2_4_2 = "2.4.2",
  V2_5 = "2.5",
  V2_5_1 = "2.5.1",
  V2_5_2 = "2.5.2",
  V2_5_3 = "2.5.3",
  V2_5_4 = "2.5.4",
  V2_5_5 = "2.5.5",
  V2_5_6 = "2.5.6",
  V2_6 = "2.6",
  V2_7 = "2.7",
  V2_7_1 = "2.7.1",
  V2_8 = "2.8",
  V2_9 = "2.9",
  V3_1 = "3.1",
  V3_2 = "3.2",
  V3_3 = "3.3",
  V3_4 = "3.4",
  V3_5 = "3.5",
  V3_6 = "3.6",
  V3_6_1 = "3.6.1",
  V3_7 = "3.7",
  V3_7_1 = "3.7.1",
  V3_7_2 = "3.7.2",
  V3_8 = "3.8",
  V3_9 = "3.9",
  V4_1 = "4.1",
  V4_1_1 = "4.1.1",
  V4_1_2 = "4.1.2",
  V4_1_3 = "4.1.3",
  V4_1_4 = "4.1.4",
  V4_1_5 = "4.1.5",
  V4_1_6 = "4.1.6",
  V4_1_7 = "4.1.7",
  V4_1_8 = "4.1.8",
  V4_1_9 = "4.1.9",
  V4_1_10 = "4.1.10",
  V4_2 = "4.2",
  V4_2_1 = "4.2.1",
  V4_2_2 = "4.2.2",
  V4_2_3 = "4.2.3",
  V4_2_4 = "4.2.4",
  V4_2_5 = "4.2.5",
  V4_3 = "4.3",
  V4_3_1 = "4.3.1",
  V4_3_2 = "4.3.2",
  V4_3_3 = "4.3.3",
  V4_3_4 = "4.3.4",
  V4_3_5 = "4.3.5",
  V4_3_6 = "4.3.6",
  V4_3_7 = "4.3.7",
  V4_4 = "4.4",
  V4_4_1 = "4.4.1",
  V4_4_2 = "4.4.2",
  V4_4_3 = "4.4.3",
  V4_4_4 = "4.4.4",
  V4_4_5 = "4.4.5",
  V4_4_6 = "4.4.6",
  V4_4_7 = "4.4.7",
  V4_4_8 = "4.4.8",
  V4_5 = "4.5",
  V4_5_1 = "4.5.1",
  V4_5_2 = "4.5.2",
  V4_5_3 = "4.5.3",
  V4_6 = "4.6",
  V4_6_1 = "4.6.1",
  V4_6_2 = "4.6.2",
  V4_6_3 = "4.6.3",
  V4_6_4 = "4.6.4",
  V4_6_5 = "4.6.5",
  V4_6_6 = "4.6.6",
  V5_1 = "5.1",
  V5_2 = "5.2",
  V5_3 = "5.3",
  V5_4 = "5.4",
  V5_5 = "5.5",
  V5_6 = "5.6",
  V5_7 = "5.7",
  V5_8 = "5.8",
  V6_0 = "6.0",
  V6_1 = "6.1",
  V6_2 = "6.2",
  EXHIBIT_A = "EXHIBIT A",
  EXHIBIT_B = "EXHIBIT B",
  EXHIBIT_C = "EXHIBIT C",
  EXHIBIT_D = "EXHIBIT D",
  EXHIBIT_E = "EXHIBIT E",
  EXHIBIT_F = "EXHIBIT F",
  EXHIBIT_G = "EXHIBIT G"
}

export enum DealExceptionApprovedBy {
  Underwriter = "Underwriter",
  Director = "Director",
  CreditReviewCommittee = "Credit Review Committee"
}
