import { Flex } from "@chakra-ui/react";
import {
  InsurancePolicyEntityType,
  PropertyInsurancePolicyView
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { values } from "lodash";
import { useEffect, useMemo } from "react";
import { NOT_AVAILABLE } from "../../constants/common";
import { useInsurancePolicyViewHooks } from "../../hooks/insurance-policy/InsurancePolicyView.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import { AccordionSections } from "../ScrollableSections";
import { SectionHeader } from "../form-builder/FormBuilder";
import { CreateInsurancePolicyButton } from "../insurance-policy/CreateInsurancePolicyButton";
import { useInsurancePolicyModalsHooks } from "../insurance-policy/insurancePolicyModal.hooks";
import { PropertyInsurancePolicyModal } from "../insurance-policy/property/PropertyInsurancePolicyModal";
import { PropertyInsurancePolicyCards } from "../insurance-policy/property/card/PropertyInsurancePolicyCards";
import ModalContainer from "../modal-container/ModalContainer";

type InsurancePolicySectionProps = {
  navigationPath?: NavigationPath;
  propertyId: string;
  dealId: string;
};

export const InsurancePolicySection = (props: InsurancePolicySectionProps) => {
  const { dealId, navigationPath, propertyId } = props;
  const {
    onEdit,
    isInsurancePolicyEditModalOpen,
    onToggleInsurancePolicyEditModal,

    isInsurancePolicyRemoveModalOpen,
    handleInsurancePolicyRemoveModalToggle,
    onInsuredEntityRemove
  } = useInsurancePolicyModalsHooks();

  const {
    getBatchHandler,
    insurancePolicyViewState,
    getInsurancePolicyViewById
  } = useInsurancePolicyViewHooks();

  useEffect(() => {
    getBatchHandler({ entityId: propertyId });
  }, [propertyId]);

  const propertyInsurancePolicyViews = useMemo(() => {
    return values(insurancePolicyViewState.entities)
      .filter(
        (x) =>
          x?.entityType === InsurancePolicyEntityType.Property &&
          x?.entityIds?.includes(propertyId)
      )
      .filter(removeEmpty);
  }, [insurancePolicyViewState]);

  const selectedInsurancePolicyView = getInsurancePolicyViewById() || undefined;
  const propertyAddress =
    selectedInsurancePolicyView?.dealEntities[dealId]?.[propertyId]
      ?.entityName || NOT_AVAILABLE;
  const policyNumber =
    selectedInsurancePolicyView?.policy?.number || NOT_AVAILABLE;
  const entityType = selectedInsurancePolicyView?.entityType;
  return (
    <>
      <AccordionSections
        customKey="property-insurance-policies"
        sections={[
          {
            header: <SectionHeader header="Property Insurance Information" />,
            body: (
              <Flex direction="column" gap="12px">
                <CreateInsurancePolicyButton dealId={props.dealId} />
                <PropertyInsurancePolicyCards
                  onEdit={onEdit}
                  onRemove={handleInsurancePolicyRemoveModalToggle}
                  propertyInsurancePolicyViews={
                    propertyInsurancePolicyViews as PropertyInsurancePolicyView[]
                  }
                />
              </Flex>
            )
          }
        ]}
        navigationPath={navigationPath}
      />
      <PropertyInsurancePolicyModal
        showDomainStep={false}
        isOpen={isInsurancePolicyEditModalOpen}
        onClose={onToggleInsurancePolicyEditModal}
        dealId={props.dealId}
      />
      <ModalContainer
        isShow={isInsurancePolicyRemoveModalOpen}
        onCloseModal={handleInsurancePolicyRemoveModalToggle}
        header={`Removing a ${
          entityType || NOT_AVAILABLE
        } from an Insurance Policy`}
        submit={{
          onConfirm: () =>
            onInsuredEntityRemove({ entityId: propertyId, entityType })
        }}
        body={`Are you sure you want to remove property ${propertyAddress} from insurance policy #${policyNumber}?`}
      />
    </>
  );
};
