import { LabelValueWithStatus, Property } from "@elphi/types";
import { buildPropertyName } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { pickBy, values } from "lodash";
import { useMemo } from "react";
import { NOT_AVAILABLE } from "../../../../../../constants/common";
import { useInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePropertyInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/propertyInsurancePolicy.hooks";
import { usePropertyHooks } from "../../../../../../hooks/property.hooks";
import { useElphiToast } from "../../../../../toast/toast.hook";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { orderPropertiesByDealId } from "../utils/formUtils";

const buildOption = (r: {
  property?: Property;
  loanIdentifier?: string;
  isDisabled?: boolean;
}): LabelValueWithStatus | null => {
  const { property, loanIdentifier, isDisabled } = r;
  if (!property) {
    return null;
  }
  const propertyName = buildPropertyName(property) || NOT_AVAILABLE;
  return {
    label: `${propertyName} | Loan #${loanIdentifier || NOT_AVAILABLE}`,
    value: property?.id,
    isDisabled
  };
};

export const usePropertiesSelectStepHook = (r: {
  state: Partial<PropertyInsurancePolicyState>;
}) => {
  const { state } = r;
  const { selectedDeal } = usePropertyInsurancePolicyHooks();
  const { getPropertyBatch, getPropertyBatchResponse } = usePropertyHooks();
  const { propertyState } = usePropertyHooks();
  const { selectedInsurancePolicy } = useInsurancePolicyHooks();

  const { errorToast } = useElphiToast();

  const fetchProperties = async () => {
    if (!state.entityIds) {
      return;
    }

    await getPropertyBatch(state.entityIds).catch((_) => {
      errorToast({
        title: "Failed to fetch deal properties",
        description: "Failed to fetch deal properties"
      });
    });
  };

  const isLoading = getPropertyBatchResponse.isFetching;

  const options = useMemo(() => {
    const idsSet = new Set(state.entityIds || []);
    values(propertyState.entities).forEach((property) => {
      if (
        property?.id &&
        selectedDeal?.id &&
        property?.dealRelation?.includes(selectedDeal?.id)
      ) {
        idsSet.add(property.id);
      }
    });
    const properties = pickBy(
      propertyState.entities,
      (property) => property?.id && idsSet.has(property.id)
    );

    const orderedProperties = orderPropertiesByDealId({
      properties: values(properties).filter(removeEmpty),
      dealId: selectedDeal?.id || ""
    });
    return orderedProperties
      .map((property) => {
        const isPropertyBelongToSelectedDeal =
          selectedDeal?.id && property?.dealRelation?.includes(selectedDeal.id);
        return buildOption({
          property,
          loanIdentifier: isPropertyBelongToSelectedDeal
            ? selectedDeal?.LoanIdentifier
            : selectedInsurancePolicy?.deals?.[
                property?.dealRelation?.[0] || ""
              ]?.loanIdentifier,
          isDisabled: !isPropertyBelongToSelectedDeal
        });
      })
      .filter(removeEmpty);
  }, [propertyState]);

  return {
    isLoading,
    options,
    fetchProperties
  };
};
