// Environment configuration
export enum ElphiDomain {
  Localhost = "localhost",
  Test = "limaone-los-test.elphimortgage.com",
  Demo = "client-demo.elphimortgage.com",
  POC = "limaone-los-poc.elphimortgage.com",
  Prod = "limaone-los.elphimortgage.com"
}
export enum ElphiEnv {
  Localhost = "localhost",
  Test = "dev",
  Demo = "demo",
  POC = "poc",
  Prod = "prod"
}
export const envMap: Record<ElphiDomain, ElphiEnv> = {
  [ElphiDomain.Localhost]: ElphiEnv.Localhost,
  [ElphiDomain.Test]: ElphiEnv.Test,
  [ElphiDomain.Demo]: ElphiEnv.Demo,
  [ElphiDomain.POC]: ElphiEnv.POC,
  [ElphiDomain.Prod]: ElphiEnv.Prod
};

////////////////
//
//
//  TODO: move to env_react_baseUrl from process.env
//
//
/**
 *
 * @param orgId
 * @param prefix
 * @returns the base url with the following format http/s://url.com/prefix/orgId
 * example
 * getBaseUrl("12","api/v1/")
 * returns domain.com/api/v1/12
 */
export const getBaseUrl = (orgId: string, prefix?: string) => {
  const routePrefix = orgId ? `${orgId}` : "";
  const serviceVersion = prefix || "";
  switch (window.location.hostname) {
    case ElphiDomain.Localhost:
      return `http://localhost:3000/${serviceVersion}${routePrefix}`;
    case ElphiDomain.Demo:
      return `https://api-client-demo.elphimortgage.com/${serviceVersion}${routePrefix}`;
    case ElphiDomain.Test:
      return `https://api-limaone-los-test.elphimortgage.com/${serviceVersion}${routePrefix}`;
    case ElphiDomain.POC:
      return `https://api-limaone-los-poc.elphimortgage.com/${serviceVersion}${routePrefix}`;
    case ElphiDomain.Prod:
      return `https://api-limaone-los.elphimortgage.com/${serviceVersion}${routePrefix}`;
    default:
      if (process.env.REACT_APP_API_URL) {
        return `${process.env.REACT_APP_API_URL}/${serviceVersion}${routePrefix}`;
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const isProd = () => {
  if (process.env.REACT_APP_IS_PROD) {
    return process.env.REACT_APP_IS_PROD;
  }
  return window.location.hostname === ElphiDomain.Prod;
};
export const getBoxStorageUrl = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Localhost:
    case ElphiDomain.Demo:
      return "https://elphil1c.app.box.com";
    case ElphiDomain.Test:
    case ElphiDomain.POC:
      return "https://elphil1c.app.box.com";
    case ElphiDomain.Prod:
      return "https://limaonecapital.app.box.com";
    default:
      if (process.env.REACT_APP_BOX_URL) {
        return process.env.REACT_APP_BOX_URL;
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};
export const getFirebaseConfig = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Demo:
      return {
        apiKey: "AIzaSyCB5cvEQA7zvAey3wQMhMKMTHkcNqoI3Dc",
        authDomain: "client-demo-416211.firebaseapp.com",
        projectId: "client-demo-416211",
        storageBucket: "client-demo-416211.appspot.com",
        messagingSenderId: "784050144485",
        appId: "1:784050144485:web:cfe8270c94e4d46c32800c"
      };

    case ElphiDomain.Localhost:
    case ElphiDomain.Test:
      if (process.env.IS_DEMO) {
        return {
          apiKey: "AIzaSyCB5cvEQA7zvAey3wQMhMKMTHkcNqoI3Dc",
          authDomain: "client-demo-416211.firebaseapp.com",
          projectId: "client-demo-416211",
          storageBucket: "client-demo-416211.appspot.com",
          messagingSenderId: "784050144485",
          appId: "1:784050144485:web:cfe8270c94e4d46c32800c"
        };
      }
      return {
        apiKey: "AIzaSyBEv6TwEATQxGMKApfh42ehYuuZupswTV8",
        authDomain: "lima-one-test.firebaseapp.com",
        projectId: "lima-one-test",
        storageBucket: "lima-one-test.appspot.com",
        messagingSenderId: "91927893941",
        appId: "1:91927893941:web:a8ddc13ffc23f4e5cbabfc",
        measurementId: "G-BLTJ3DMSHS"
      };
    case ElphiDomain.POC:
      return {
        apiKey: "AIzaSyDBtjKVbjLwA61KPrUWujMD2oyQ6Ht_uMc",
        authDomain: "lima-one-poc.firebaseapp.com",
        projectId: "lima-one-poc",
        storageBucket: "lima-one-poc.appspot.com",
        messagingSenderId: "599635795614",
        appId: "1:599635795614:web:9adfd78c9d59d45883a58f",
        measurementId: "G-7B0N4FW1N6"
      };
    case ElphiDomain.Prod:
      return {
        apiKey: "AIzaSyCOFg2zatYYpJMw-hyT1T3-jtAe8kcnlDQ",
        authDomain: "lima-one-prod.firebaseapp.com",
        projectId: "lima-one-prod",
        storageBucket: "lima-one-prod.appspot.com",
        messagingSenderId: "763661855152",
        appId: "1:763661855152:web:714ac3d85450d876028e75",
        measurementId: "G-3V7810H6H7"
      };
    default:
      if (process.env.REACT_APP_FB_API_KEY) {
        return {
          apiKey: process.env.REACT_APP_FB_API_KEY,
          authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
          projectId: process.env.REACT_APP_FB_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
          appId: process.env.REACT_APP_FB_APP_ID
        };
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const getUsersnapConfig = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Test:
      return {
        apiKey: "4847990d-5171-4714-bdcf-5f28bac1ddb7"
      };
    case ElphiDomain.POC:
      return {
        apiKey: "c4aa6cc4-4275-4350-9e37-5cd329d5fe9b"
      };
    case ElphiDomain.Prod:
      return {
        apiKey: "6f02c520-4cae-4694-928a-ee51f9ffffc3"
      };
    default:
      if (process.env.REACT_APP_USER_SNAP) {
        return { apiKey: process.env.REACT_APP_USER_SNAP };
      }
      return undefined;
  }
};

export const getOktaUrl = () => {
  switch (window.location.hostname) {
    case ElphiDomain.Localhost:
    case ElphiDomain.Test:
      return "https://elphi.okta.com/home/oidc_client/0oa15ygz73USuQFPz697/aln177a159h7Zf52X0g8";
    case ElphiDomain.POC:
      return "https://elphi.okta.com/home/oidc_client/0oa1kidn82TfD7sXE697/aln177a159h7Zf52X0g8";
    case ElphiDomain.Prod:
      return "https://limaone.okta.com/home/oidc_client/0oaqphr00gLHRjFiV357/aln177a159h7Zf52X0g8";
    default:
      if (process.env.REACT_APP_OKTA_URL) {
        return process.env.REACT_APP_OKTA_URL;
      }
      throw `host: ${window.location.hostname} not configured`;
  }
};

export const isLocal = () => window.location.hostname === ElphiDomain.Localhost;
