import { Flex, FlexProps, TabPanel } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  wrapperProps?: FlexProps;
};

export const StepPanel = (props: Props) => {
  const { children, wrapperProps = {} } = props;
  return (
    <TabPanel>
      <Flex
        h={500}
        p={5}
        borderRadius={6}
        borderWidth={1}
        borderColor={"gray.200"}
        width={"100%"}
        height={"fit-content"}
        direction={"column"}
        overflowY={"auto"}
        {...wrapperProps}
      >
        {children}
      </Flex>
    </TabPanel>
  );
};
