import { Flex } from "@chakra-ui/react";
import { PropertyInsurancePolicyView } from "@elphi/types";
import { keys, values } from "lodash";
import { CustomCollapse } from "../../../../../common-components";
import { MaximizedInfo } from "../../../../../common-components/card";
import { NOT_AVAILABLE } from "../../../../../constants/common";

export const DealPropertyInsurancePolicyList = (props: {
  view: PropertyInsurancePolicyView;
}) => {
  const { view } = props;
  const dealLoanIdentifierMap = view.deals;
  const dealIds = keys(view.dealEntities);
  return dealIds.map((dealId) => (
    <DealPropertyInsurancePolicyAccordion
      key={dealId}
      loanIdentifier={dealLoanIdentifierMap[dealId].loanIdentifier}
      propertyNames={values(view.dealEntities[dealId]).map((p) => p.entityName)}
    />
  ));
};

const DealPropertyInsurancePolicyAccordion = (props: {
  loanIdentifier?: string;
  propertyNames: string[];
}) => {
  const { loanIdentifier, propertyNames } = props;
  const title = loanIdentifier ? `Loan ${loanIdentifier}` : NOT_AVAILABLE;
  return (
    <CustomCollapse
      rightContent={`${propertyNames.length} properties`}
      options={{
        containerBorderColor: "gray.200"
      }}
      title={title}
      customBody={
        <Flex
          borderRadius="10px"
          p="10px"
          backgroundColor="white"
          direction="column"
          gap="16px"
        >
          {propertyNames.map((propertyName, index) => (
            <MaximizedInfo
              key={index}
              title={`Property ${index + 1}`}
              subTitle={propertyName}
            />
          ))}
        </Flex>
      }
    />
  );
};
