import { BaseEntity } from "../db/baseEntity.types";
import { ElphiEntityType } from "./elphiEntities.types";
import { FieldStatus } from "./fieldMeta.types";

export enum KeyClosingTaskType {
  Required = "required",
  Optional = "optional",
  None = "none"
}

export enum AutoGenerateOptions {
  Always = "always",
  Conditional = "conditional",
  Never = "never"
}

export type FieldSelection = {
  [key: string]: { fieldPath: string; status?: FieldStatus; index: number };
};

export type EntitiesFieldSelection = {
  [P in ElphiEntityType]: FieldSelection;
};

export type TaskConfiguration = BaseEntity<{
  name: string;
  status: "active" | "disabled";
  taskRuleTemplateId: string;
  taskTemplateId: string;
  keyClosingTaskType: KeyClosingTaskType;
  autoGenerate: "always" | "conditional" | "never";
  taskEntity: ElphiEntityType;
  dataToVerify?: EntitiesFieldSelection;
  dataToOrder?: EntitiesFieldSelection;
}>;

export type TaskConfigurationDataToType = keyof Pick<
  TaskConfiguration,
  "dataToVerify" | "dataToOrder"
>;
