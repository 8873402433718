import { ReactNode, useState } from "react";
import { StatsigOptions, StatsigProvider, StatsigUser } from "statsig-react";
import { auth } from "../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../hooks/authStateChange.hooks";
import { envMap } from "../utils/envUtils";
export const StasigUserProvider = (props: { children: ReactNode }) => {
  const [user, setUser] = useState<StatsigUser>({
    userID: auth.currentUser?.uid,
    email: auth.currentUser?.email || undefined
  });

  useAuthStateChangeHook({
    onAuthStateChange: (u) => {
      setUser({
        userID: u?.uid,
        email: u?.email || undefined
      });
    }
  });
  const statsigOptions: StatsigOptions = {
    environment: {
      tier: envMap[window.location.hostname]
    }
  };
  return (
    <StatsigProvider
      user={user}
      setUser={setUser}
      options={statsigOptions}
      sdkKey="client-R0XKXN2JKqcu2Loy5obtDPEMRF9Py4JjmqoTUko5U7B"
      waitForInitialization={true}
    >
      {props.children}
    </StatsigProvider>
  );
};
