import { FieldType } from "@elphi/types";
import { ReactNode } from "react";
import { EMPTY } from "../../../constants/common";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import ModalContainer from "../../modal-container/ModalContainer";

type FormRequest = Partial<{
  name: string;
}>;
type ModalAction = "create" | "clone";
type GroupModalActionProps<Action extends ModalAction> = {
  header: string;
  action: Action;
  isShow: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onSubmit: (r: FormRequest) => Promise<{}>;
};
type CreateGroupModalProps = GroupModalActionProps<"create">;
type CloneGroupModalProps = GroupModalActionProps<"clone"> & {
  cloneGroup: FormRequest;
  children: ReactNode;
};
type GroupModalProps = CreateGroupModalProps | CloneGroupModalProps;

export const GroupModal = (props: GroupModalProps) => {
  const { onChange, state } = useFormBuilderStateHandler<FormRequest>({
    initialState:
      props.action === "clone"
        ? { ...props.cloneGroup }
        : {
            name: EMPTY
          }
  });

  const handleOnSubmit = () => {
    props.onSubmit(state).finally(() => {
      props.onClose();
    });
  };

  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onClose}
      header={props.header}
      body={
        <>
          {props.action === "clone" && props.children}
          <FormBuilder
            customKey={"groupForm"}
            elphiView={"form"}
            onChange={onChange}
            sections={[
              {
                inputs: [
                  {
                    label: "Name",
                    labelPosition: "up",
                    fieldType: FieldType.String,
                    fieldKey: ["name"],
                    currentValue: state.name,
                    isRequired: true
                  }
                ]
              }
            ]}
          />
        </>
      }
      submit={{
        showClose: true,
        onConfirm: handleOnSubmit,
        isLoading: props.isLoading,
        isDisabled: !state.name && !props.isLoading
      }}
    />
  );
};
