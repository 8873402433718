import {
  BaseInsurancePolicy,
  FieldType,
  InsurancePolicyEntityType,
  LabelValue
} from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../../constants/common";
import { useInsurancePolicyHooks } from "../../../../../hooks/insurance-policy/insurancePolicy.hooks";
import { useSearchApiWithParamsHooks } from "../../../../../hooks/searchBar.hooks";
import { SearchComponentProps } from "../../../../SearchComponent";
import { FuzzySearchSelect } from "../../../../search/fuzzy/FuzzySearchSelect";

const buildOption = (
  insurancePolicy: BaseInsurancePolicy<InsurancePolicyEntityType>
) => {
  const { policyNumber, policyProviderInfo, id, domainConfigurationId } =
    insurancePolicy;
  return {
    label: `${policyNumber} | ${policyProviderInfo?.name || NOT_AVAILABLE}`,
    value: id,
    domainConfigurationId
  };
};

type SearchProps = {
  preventInitialFetch?: boolean;
  createNewPolicyOptionId?: string;
  domainConfigurationId?: string;
  entityType?: InsurancePolicyEntityType;
  filter?: (
    insurancePolicy?: BaseInsurancePolicy<InsurancePolicyEntityType>
  ) => boolean;
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "isDisabled"
  | "isClearable"
  | "wrapperStyle"
  | "onInputChange"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;

export const InsurancePolicySearch = (props: SearchProps) => {
  const { searchApi, searchResponse, insurancePolicyState } =
    useInsurancePolicyHooks();
  const { searchApiWithParams } = useSearchApiWithParamsHooks();
  const defaultOptions: LabelValue[] = [];

  if (props.createNewPolicyOptionId) {
    defaultOptions.push({
      value: props.createNewPolicyOptionId,
      label: "+ Add new Policy"
    });
  }

  const baseFilter = (r: { insurancePolicyId: string }) => {
    const { insurancePolicyId } = r;
    const insurancePolicy = insurancePolicyState.entities[insurancePolicyId];
    return (
      insurancePolicy?.domainConfigurationId === props.domainConfigurationId &&
      (!props.filter || props.filter?.(insurancePolicy))
    );
  };

  const handleOptionsFiltering = (
    option: LabelValue & {
      data?: BaseInsurancePolicy<InsurancePolicyEntityType>;
    },
    inputValue: string
  ) => {
    const baseFilterRes = baseFilter({ insurancePolicyId: option.value });
    if (option.value === props.createNewPolicyOptionId || baseFilterRes) {
      return true;
    }
    return option.label?.includes(inputValue) && baseFilterRes;
  };

  return (
    <FuzzySearchSelect
      searchApi={searchApiWithParams({
        searchApi,
        additionalRequestData: {
          params: {
            domainConfigurationId: props.domainConfigurationId,
            entityType: props.entityType
          }
        }
      })}
      defaultOptions={defaultOptions}
      filterOption={handleOptionsFiltering}
      searchResponse={searchResponse}
      state={insurancePolicyState}
      fieldType={FieldType.SingleSelect}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      onInputChange={props.onInputChange}
      label={"Search Insurance Policy"}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["policyNumber"],
        shouldSort: true
      }}
      buildOption={buildOption}
    />
  );
};
