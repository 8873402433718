import { Flex } from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { buildPropertyName } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useMemo } from "react";
import { CustomCollapse } from "../../../../../../common-components";
import { EMPTY, NOT_AVAILABLE } from "../../../../../../constants/common";
import { usePropertyHooks } from "../../../../../../hooks/property.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { StepPanel } from "../components/StepPanel";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { orderPropertiesByDealId } from "../utils/formUtils";
import { PropertyBuildingsFormList } from "./PropertyBuildingsFormList";
import { PropertyCoveragesData } from "./PropertyCoveragesData";

type CoverageDataStepProps = {
  onChange: (value: OnChangeInput) => void;
  state: PropertyInsurancePolicyState;
  dealId: string;
};

export const CoverageDataStep = (props: CoverageDataStepProps) => {
  const { onChange, state, dealId } = props;
  const { propertyState } = usePropertyHooks();
  const dealPropertiesIds = new Set(
    state.entityIds?.filter((id) =>
      propertyState.entities?.[id]?.dealRelation?.includes(dealId)
    ) || []
  );
  const policyProperties = state.entityIds || [];

  const properties = useMemo(() => {
    if (!propertyState.entities) {
      return [];
    }
    const options = policyProperties
      .map((propertyId) => propertyState.entities[propertyId])
      .filter(removeEmpty);

    return orderPropertiesByDealId({ properties: options, dealId });
  }, [propertyState.entities, policyProperties, dealId]);

  return (
    <Flex h="100%" overflowY="auto">
      <Flex width="100%" direction="column" gap="16px">
        {properties.map((property: Property, index: number) => {
          if (!property) {
            return <></>;
          }
          const propertyId = property?.id || EMPTY;
          const dealId = state.entities?.[propertyId]?.dealId || EMPTY;
          const propertyName = buildPropertyName(property) || NOT_AVAILABLE;
          const sectionName = `${propertyName} | Loan #${
            state.deals?.[dealId]?.loanIdentifier || NOT_AVAILABLE
          }`;
          const isDisabled = !dealPropertiesIds.has(propertyId);

          return (
            <CustomCollapse
              key={index}
              options={{
                containerColor: "white",
                containerBorderColor: "gray.200"
              }}
              title={sectionName}
              customBody={
                <StepPanel>
                  <Flex direction="column" gap="16px">
                    <PropertyCoveragesData
                      isDisabled={isDisabled}
                      property={property}
                      propertyId={propertyId}
                      onChange={onChange}
                      state={state}
                    />
                    <Flex direction="column" pl="8px">
                      <PropertyBuildingsFormList
                        propertyId={propertyId}
                        state={state}
                        isDisabled={isDisabled}
                        onChange={onChange}
                        sectionName={sectionName}
                      />
                    </Flex>
                  </Flex>
                </StepPanel>
              }
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
