import { BaseInsurancePolicy, InsurancePolicyEntityType } from "@elphi/types";

export const buildInsurancePolicyName = (
  insurancePolicy?: Partial<BaseInsurancePolicy<InsurancePolicyEntityType>>
) => {
  const policyNumber = insurancePolicy?.policyNumber;
  const providerName = insurancePolicy?.policyProviderInfo?.name;

  if (!policyNumber) {
    return undefined;
  }
  const policyNumberLabel = `Policy #${policyNumber}`;
  return !providerName
    ? policyNumberLabel
    : `${policyNumberLabel} | ${providerName}`;
};
