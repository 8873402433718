import {
  InsurancePolicyEntityType,
  PropertyInsurancePolicy
} from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import serviceBuilder from "../builders/api.builder";
import { RolodexServiceProviderRequest } from "../rolodex";
import { insurancePolicyAdapter } from "./insurancePolicy.adapter";

export type InsurancePolicyProvidersServiceRequest = {
  dealId: string;
  insurancePolicy: Partial<PropertyInsurancePolicy>;
  serviceProvider?: Partial<RolodexServiceProviderRequest>;
};

type InsurancePolicyCreateUpDateResponse = {
  policyId: string;
  policyProvider: {
    companyId: string;
    branchId: string;
    representativeId: string;
  };
};

export const basePropertyInsurancePolicyApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "PropertyInsurancePolicyApi",
  tagTypes: ["PropertyInsurancePolicy", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "insurance-policy"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: insurancePolicyAdapter
  })
});

export const propertyInsurancePolicyApi =
  basePropertyInsurancePolicyApi.injectEndpoints({
    endpoints: (builder) => ({
      createWithProvider: builder.mutation<
        InsurancePolicyCreateUpDateResponse,
        InsurancePolicyProvidersServiceRequest
      >({
        query: (r) => {
          return {
            url: `/`,
            method: "POST",
            body: {
              ...r,
              insurancePolicy: {
                ...r.insurancePolicy,
                entityType: InsurancePolicyEntityType.Property
              }
            }
          };
        }
      }),
      updateWithProvider: builder.mutation<
        InsurancePolicyCreateUpDateResponse,
        InsurancePolicyProvidersServiceRequest
      >({
        query: (r) => {
          return {
            url: `/${r.insurancePolicy.id}`,
            method: "PUT",
            body: {
              ...r,
              insurancePolicy: {
                ...r.insurancePolicy,
                entityType: InsurancePolicyEntityType.Property
              }
            }
          };
        }
      })
    })
  });
