import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProviderView,
  ServiceProviderEntityType,
  ServiceProviderType,
  Status,
  StatusCode
} from "@elphi/types";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../constants/common";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { PropertyInsurancePolicyModalContent } from "../../../insurance-policy/property/PropertyInsurancePolicyModalContent";
import ModalContainer from "../../../modal-container/ModalContainer";
import { WizardContainer } from "../../../wizard-container/WizardContainer";
import { DomainStepContent } from "../wizard/steps/DomainStepContent";
import { EntitiesStepContent } from "../wizard/steps/EntitiesStepContent";
import { ProvidersFormStepContent } from "../wizard/steps/provider-step-content/ProvidersFormStepContent";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../wizard/steps/provider-step-content/providersFormStep.utils";
import { useServiceProviderCreateForm } from "./serviceProviderModal.hooks";

export const ServiceProviderCreateModal = (props: {
  isShow: boolean;
  onClose: () => void;
  dealId: string;
  partyId?: string;
  showEntitiesStep: boolean;
  filteredItems: RolodexServiceProviderView[];
}) => {
  const { isShow, onClose, dealId, partyId, showEntitiesStep, filteredItems } =
    props;
  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();
  const { createHandler, upsertResponse } = useServiceProviderHooks();
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [isProviderFormValid, setIsProviderFormValid] =
    useState<boolean>(false);

  const {
    onChange: onChangeCompanyState,
    state: companyState,
    setState: setCompanyState,
    selectedProviderId: selectedCompanyId,
    setSelectedProviderId: setSelectedCompanyId
  } = useServiceProviderCreateForm<CompanyServiceProvider>();

  const {
    onChange: onChangeBranchState,
    state: branchState,
    setState: setBranchState,
    selectedProviderId: selectedBranchId,
    setSelectedProviderId: setSelectedBranchId
  } = useServiceProviderCreateForm<BranchServiceProvider>();

  const {
    onChange: onChangeRepState,
    state: repState,
    setState: setRepState,
    selectedProviderId: selectedRepId,
    setSelectedProviderId: setSelectedRepId
  } = useServiceProviderCreateForm<RepresentativeServiceProvider>();

  useEffect(() => {
    if (isShow) {
      setSelectedConfiguration(EMPTY);
    }
  }, [isShow]);

  useEffect(() => {
    initProvidersStepState();
    setSelectedEntities([]);
  }, [selectedConfiguration?.id]);

  const initProvidersStepState = () => {
    if (!selectedConfiguration) {
      setCompanyState({
        type: ServiceProviderType.Company
      });
    }
    if (selectedConfiguration) {
      setCompanyState({
        type: ServiceProviderType.Company,
        domainConfigurationId: selectedConfiguration.id
      });
    }
    setBranchState({ type: ServiceProviderType.Branch });
    setRepState({ type: ServiceProviderType.Representative });
    setSelectedProvidersState();
  };

  const setSelectedProvidersState = () => {
    setSelectedCompanyId(EMPTY);
    setSelectedBranchId(EMPTY);
    setSelectedRepId(EMPTY);
  };

  const handleProviderFormValidation = (isValid: boolean) => {
    setIsProviderFormValid(isValid);
  };

  const handleOnExistBranchRep = (repId: string) => {
    const existedItem = filteredItems.find(
      (x) => x.branchId === selectedBranchId && x.repId === repId
    );
    setSelectedEntities(existedItem?.dealEntities?.[dealId]?.entityIds || []);
  };

  const handleOnSubmit = () => {
    if (!selectedConfiguration || !isProviderFormValid) {
      return;
    }
    createHandler({
      dealId,
      partyId,
      entityIds: selectedEntities,
      entityType: selectedConfiguration.entityType,
      domainConfigurationId: selectedConfiguration.id,
      company: getCompanyTemplate(companyState),
      branch: getBranchTemplate(branchState),
      rep: getRepTemplate(repState)
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        onClose();
      }
    });
  };

  const domainStep = {
    label: "Domain",
    isValid: !!selectedConfiguration,
    content: <DomainStepContent filter={(x) => x.status === Status.Active} />
  };

  const providersStep = {
    label: "Providers",
    isValid: isProviderFormValid,
    content: (
      <ProvidersFormStepContent
        companyForm={{
          state: companyState,
          onChangeState: onChangeCompanyState,
          setState: (selected) => {
            setCompanyState(selected);
          },
          selectedProviderId: selectedCompanyId,
          setSelectedProviderId: (id) => {
            setSelectedCompanyId(id);
          }
        }}
        branchForm={{
          state: branchState,
          onChangeState: onChangeBranchState,
          setState: (selected) => {
            setBranchState(selected);
          },
          selectedProviderId: selectedBranchId,
          setSelectedProviderId: (id) => {
            setSelectedBranchId(id);
          }
        }}
        repForm={{
          state: repState,
          onChangeState: onChangeRepState,
          setState: (selected) => {
            setRepState(selected);
          },
          selectedProviderId: selectedRepId,
          setSelectedProviderId: (id) => {
            setSelectedRepId(id);
            handleOnExistBranchRep(id);
          }
        }}
        onFormValidation={handleProviderFormValidation}
        showAttached={false}
      />
    )
  };

  const entitiesStep = {
    label: "Entities",
    isValid: !!selectedEntities.length,
    content: (
      <EntitiesStepContent
        currentValue={selectedEntities}
        onChange={setSelectedEntities}
      />
    )
  };

  const steps = [domainStep, providersStep];
  if (showEntitiesStep) {
    steps.push(entitiesStep);
  }

  const isInsurancePolicyDomainConfig =
    selectedConfiguration?.entityType ===
    ServiceProviderEntityType.InsurancePolicy;

  return (
    <>
      <ModalContainer
        maxWidth={1110}
        isShow={isShow}
        onCloseModal={onClose}
        header={"Create a Service Provider"}
        body={
          <>
            {isInsurancePolicyDomainConfig ? (
              <PropertyInsurancePolicyModalContent
                dealId={props.dealId}
                onClose={onClose}
                showDomainStep={true}
              />
            ) : (
              <WizardContainer
                height={500}
                steps={steps}
                onCancel={onClose}
                onSubmit={handleOnSubmit}
                isLoading={upsertResponse.isLoading}
              />
            )}
          </>
        }
      />
    </>
  );
};
