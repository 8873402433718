import { Box, Flex, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { values } from "lodash";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { useInsurancePolicyViewHooks } from "../../../../hooks/insurance-policy/InsurancePolicyView.hooks";
import { useInsurancePolicyHooks } from "../../../../hooks/insurance-policy/insurancePolicy.hooks";

export const DeleteModalContent = () => {
  const { selectedInsurancePolicy } = useInsurancePolicyHooks();
  const policyNumber = selectedInsurancePolicy?.policyNumber
    ? `#${selectedInsurancePolicy.policyNumber}`
    : NOT_AVAILABLE;
  const dealIds = selectedInsurancePolicy?.dealIds || [];
  const loanIdentifiers =
    dealIds.map(
      (dealId) => selectedInsurancePolicy?.deals?.[dealId]?.loanIdentifier
    ) || [];
  return (
    <Flex direction="column" gap="16px">
      <Text>
        Are you sure you want to permanently delete insurance policy:
        {policyNumber} from the system?
      </Text>
      <Text>
        <Box as="span" fontWeight={700}>
          Note:
        </Box>{" "}
        This insurance policy contains properties from the following deals:
      </Text>
      <OrderedList lineHeight="24px">
        {loanIdentifiers.map((loanIdentifier, index) => (
          <ListItem key={index}>{loanIdentifier}</ListItem>
        ))}
      </OrderedList>
      <Text mt={5} color={"red.400"}>
        This action is irreversible. Are you sure you want to proceed with this
        action?
      </Text>
    </Flex>
  );
};

export const RemoveModalContent = (props: { dealId: string }) => {
  const { dealId } = props;
  const { selectedInsurancePolicy } = useInsurancePolicyHooks();
  const { insurancePolicyViewState } = useInsurancePolicyViewHooks();
  const policyNumber = selectedInsurancePolicy?.policyNumber
    ? `#${selectedInsurancePolicy.policyNumber}`
    : NOT_AVAILABLE;

  const loanIdentifier =
    selectedInsurancePolicy?.deals?.[dealId]?.loanIdentifier || NOT_AVAILABLE;

  const view =
    selectedInsurancePolicy &&
    values(insurancePolicyViewState.entities).find(
      (x) => x?.policy?.id === selectedInsurancePolicy.id
    );

  const names = values(view?.dealEntities?.[dealId]).map(
    (entity) => entity.entityName
  );

  return (
    <Flex direction="column" gap="16px">
      <Text>
        Are you sure you want to remove insurance policy: {policyNumber} from
        deal {loanIdentifier}?
      </Text>
      <Text>
        <Box as="span" fontWeight={700}>
          Note:
        </Box>{" "}
        The following properties will be removed from the insurance policy:
      </Text>
      <OrderedList lineHeight="24px">
        {names.map((name, index) => (
          <ListItem key={index}>{name}</ListItem>
        ))}
      </OrderedList>
    </Flex>
  );
};
