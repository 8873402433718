import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  Text
} from "@chakra-ui/react";
import { BusinessFeatureFlag } from "@elphi/utils";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useElphiToast } from "../../../components/toast/toast.hook";
import { auth as firebaseAuth } from "../../../firebase/firebaseConfig";
import PathVariables from "../../../routes/pathVariables";
import { LoginPageProps } from "../../../shared";
import { useAuth } from "../../../utils/authUtils";
import { exists } from "../../../utils/common";
import { getOktaUrl } from "../../../utils/envUtils";
import { GateKeeper } from "../../gate/GateKeeper";
const errorCodeToMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/user-not-found":
    case "auth/wrong-password":
    case "auth/invalid-email":
      return "Email or password was incorrect";
    case "auth/email-already-in-use":
      return "Email already in use for another account";
    case "auth/invalid-user-token":
      return "User token invalid. Please try again.";
    case "auth/user-token-expired":
      return "User token expired";
    case "auth/user-disabled":
      return "User account disabled";
    case "auth/too-many-requests":
      return "Too many unsuccessful sign in attempts. Please try again later.";
    case "auth/weak-password":
      return "The password must be at least 6 characters";
    case "auth/new-password-not-confirmed":
      return "New password not confirmed correctly";
    case "auth/repeat-password":
      return "New password repeats old password";
    case "auth/app-deleted":
    case "auth/app-not-authorized":
    case "auth/argument-error":
    case "auth/invalid-api-key":
    case "auth/invalid-tenant-id":
    case "auth/network-request-failed":
    case "auth/operation-not-allowed":
    case "auth/requires-recent-login":
    case "auth/unauthorized-domain":
    case "auth/web-storage-unsupported":
    default:
      return "Internal Server Error. Please try again later.";
  }
};
const Login: React.FC<LoginPageProps> = (_) => {
  const auth = useAuth(); //this auth is a troll, we use auth from firebaseConfig and we have this authHooks named auth
  const { orgId } = useParams();
  const { successToast, errorToast } = useElphiToast();
  const navigate = useNavigate();
  // const location = useLocation();
  // const locationState = location?.state as LocationState;
  // const from = locationState?.from?.pathname ?? PathVariables.LoanPipeline;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailFocus, setEmailFocus] = useState<string | boolean>(false);
  const [passwordFocus, setPasswordFocus] = useState<string | boolean>(false);
  const [showError, setShowError] = useState<string | boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const { getLOSUser } = useLOSUserHooks();
  const errorMessageElement = () => {
    return showError ? (
      <div
        style={{
          backgroundColor: "rgb(204,0,0)",
          borderRadius: "6px",
          marginBottom: 15
        }}
      >
        <h3
          style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#ffffff",
            padding: 4
          }}
        >
          {errorMessage}
          <div
            onClick={() => setShowError(false)}
            style={{ float: "right", marginRight: 12, cursor: "pointer" }}
          ></div>
        </h3>
      </div>
    ) : null;
  };

  const signInWithEmailAndPassword = (event: Event) => {
    event.preventDefault();
    auth.signout();
    if (exists(email, password)) {
      setIsLoading(true);
      auth
        .signin(email, password)
        .then((_: any) => {
          // const userId = result?.uid ?? "NONE";
          setIsLoading(false);
          navigate(PathVariables.SelectOrganization, { replace: true });
        })
        .catch((error) => {
          setIsLoading(false);
          const errorMessage = errorCodeToMessage(error.code);
          setShowError(true);
          setErrorMessage(errorMessage);
        });
    } else {
      setShowError(true);
      setIsLoading(false);
      setErrorMessage("Please fill out all the fields");
    }
  };

  useEffect(() => {
    if (auth?.instance?.currentUser && !orgId) {
      navigate(PathVariables.SelectOrganization, { replace: true });
    }
  }, [auth?.instance?.currentUser]);

  const signInErrorMessage = ["limaonecapital"].some((v) => email.includes(v))
    ? `only sign in with okta is allowed`
    : !email
    ? "please insert your email"
    : !password
    ? "please insert password"
    : "";

  return (
    <Box w="100%" h="100%" pt="100px">
      <Center>
        <Box p="20px" boxShadow={"dark-lg"}>
          <form
            className="form"
            onSubmit={(event: any) => signInWithEmailAndPassword(event)}
          >
            <h3 className="header text-center">LOS Login</h3>
            <InputGroup
              className={classnames({ "input-group-focus": emailFocus })}
            >
              <Flex pt="10px">
                <Box p="10px">
                  <i className="fas fa-user" />
                </Box>
                <Box pl="10px">
                  <Input
                    style={{ border: "1px solid grey" }}
                    id="login_email"
                    type="email"
                    placeholder="Email"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    value={email}
                    onChange={(event) =>
                      setEmail(event.target.value.toLowerCase())
                    }
                  />
                </Box>
              </Flex>
            </InputGroup>
            <InputGroup
              className={classnames({ "input-group-focus": passwordFocus })}
            >
              <Flex pt="10px">
                <Box p="10px">
                  <i className="fas fa-key" style={{ color: "gray" }} />
                </Box>
                <Box pl="8px">
                  <Input
                    style={{ border: "1px solid grey" }}
                    id="login_password"
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Box>
              </Flex>
            </InputGroup>
            <br />
            {errorMessageElement()}
            <Box pb="10px">
              <Button
                size="xs"
                isDisabled={!email}
                bgColor={"transparent"}
                color={"blue.400"}
                onClick={() => {
                  email &&
                    firebaseAuth
                      .sendPasswordResetEmail(email)
                      .then(() => {
                        successToast({
                          title: "Reset Password",
                          description: "reset email sent"
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        errorToast({
                          title: "Reset Password",
                          description: `failed to reset password: ${err}`
                        });
                      });
                }}
              >
                forgot password
              </Button>
            </Box>
            <Box className="text-center" style={{ backgroundColor: "white" }}>
              <Box>
                <Button
                  isLoading={isLoading}
                  isDisabled={
                    !(email && password) ||
                    ["limaonecapital"].some((v) => email.includes(v))
                  }
                  type="submit"
                  // block
                  className="btn-round mb-5"
                  onClick={(event) =>
                    signInWithEmailAndPassword(event.nativeEvent)
                  }
                >
                  Sign In
                </Button>
                <Box h={"22px"}>
                  <Text
                    color="red.300"
                    fontSize={"12px"}
                    display={signInErrorMessage ? "block" : "none"}
                  >
                    {signInErrorMessage}
                  </Text>
                </Box>
              </Box>
              <GateKeeper gate={BusinessFeatureFlag.Okta_SSO}>
                <Box>
                  <Button
                    bgColor="blue.200"
                    onClick={() => {
                      window.location.href = getOktaUrl();
                    }}
                  >
                    Sign in with okta
                  </Button>
                </Box>
              </GateKeeper>
            </Box>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default Login;
