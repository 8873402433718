import { Box, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import {
  AuditEventEntityType,
  AuditEventGroup,
  DealMilestoneType,
  FieldType,
  UserAssignmentType
} from "@elphi/types";
import { useEffect } from "react";
import {
  entityMap,
  entityRelationMap
} from "../../audit-log/modal/row/eventRow.utils";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../form-builder/InputBuilder";
import { MenuRowAction } from "../../table/MenuRowAction";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../table/TableRowSizeComponent";
import TaskConfigurationSearch from "../../task/task-configuration/TaskConfigurationSearch";
import {
  createOptionsFromEnum,
  createSelectOptions
} from "../../utils/formUtils";

type AuditEventGroupRowProps = {
  index: number;
  data: AuditEventGroup;
  onClone: (id: string) => void;
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  state: {
    [id: string]: Partial<AuditEventGroup>;
  };
  isLoadingTaskConfiguration: boolean;
};

const auditEventEntityOptions = createSelectOptions(AuditEventEntityType, {
  ...entityMap,
  ...entityRelationMap
});

export const AuditEventGroupRow = ({
  index,
  data,
  onChange,
  state,
  syncState,
  onClone,
  isLoadingTaskConfiguration
}: AuditEventGroupRowProps) => {
  const { id } = data;

  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);

  return (
    <Flex w="100%" justify="space-between">
      <TableRowSizeComponent size={SIZE_FIELD.T} withBorderRightWidth={false}>
        <TableTextCell text={index} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.name}
          fieldType={FieldType.String}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "name"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          chakraStyles={{
            valueContainer: (provided) => ({
              ...provided,
              fontSize: "12px"
            })
          }}
          currentValue={state?.[data.id]?.createdEntities}
          options={auditEventEntityOptions}
          fieldType={FieldType.MultiSelect}
          onChange={(e) => {
            onChange({
              fieldKey: [data.id, "createdEntities"],
              fieldType: FieldType.MultiSelect,
              value: e.target.value
            });
          }}
          label={"Select"}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          chakraStyles={{
            valueContainer: (provided) => ({
              ...provided,
              fontSize: "12px"
            })
          }}
          currentValue={state?.[data.id]?.deletedEntities}
          options={auditEventEntityOptions}
          fieldType={FieldType.MultiSelect}
          onChange={(e) => {
            onChange({
              fieldKey: [data.id, "deletedEntities"],
              fieldType: FieldType.MultiSelect,
              value: e.target.value
            });
          }}
          label={"Select"}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!isLoadingTaskConfiguration ? (
          <TaskConfigurationSearch
            fieldType={FieldType.MultiSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "uploadedFileTaskConfigurationIds"],
                fieldType: FieldType.MultiSelect,
                value
              });
            }}
            currentValue={
              state?.[data.id]?.uploadedFileTaskConfigurationIds || []
            }
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!isLoadingTaskConfiguration ? (
          <TaskConfigurationSearch
            fieldType={FieldType.MultiSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "deletedFileTaskConfigurationIds"],
                fieldType: FieldType.MultiSelect,
                value
              });
            }}
            currentValue={
              state?.[data.id]?.deletedFileTaskConfigurationIds || []
            }
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.dealMilestones}
          fieldType={FieldType.MultiSelect}
          options={createOptionsFromEnum(DealMilestoneType)}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "dealMilestones"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.userAssignments}
          fieldType={FieldType.MultiSelect}
          options={createOptionsFromEnum(UserAssignmentType)}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "userAssignments"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Box w="fit-content">
            <StyledInputBuilder
              currentValue={state[id]?.status}
              fieldType={FieldType.Switch}
              onChange={(e) => {
                onChange({
                  fieldKey: [id, "status"],
                  fieldType: FieldType.Switch,
                  value: e.target.value
                });
              }}
            />
          </Box>
          <MenuRowAction
            onClone={() => {
              onClone(id);
            }}
          />
        </Flex>
      </TableRowSizeComponent>
    </Flex>
  );
};
