import {
  InsurancePolicyEntityType,
  PropertyInsurancePolicy,
  StatusCode
} from "@elphi/types";
import { keys, pickBy } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../../apis/rtk/response.handler";
import { useElphiToast } from "../../components/toast/toast.hook";
import { RootState } from "../../redux/store";
import { dealApi } from "../../redux/v2/deal";
import {
  InsurancePolicyProvidersServiceRequest,
  propertyInsurancePolicyApi
} from "../../redux/v2/insurance-policy/propertyInsurancePolicy.service";
import {
  BranchServiceProviderRequest,
  CompanyServiceProviderRequest,
  RepServiceProviderRequest
} from "../../redux/v2/rolodex";
import { useRolodexBranchRepRelation } from "../rolodexBranchRepRelation.hooks";
import { useInsurancePolicyHooks } from "./insurancePolicy.hooks";

export const usePropertyInsurancePolicyHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const { branchRepState } = useRolodexBranchRepRelation();
  const [getDealPropertiesApi, getDealPropertiesApiResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const selectedDeal = useSelector(
    (state: RootState) =>
      (state.deal.selectedId && state.deal.entities[state.deal.selectedId]) ||
      undefined
  );

  const { insurancePolicyState } = useInsurancePolicyHooks();
  const [createApi, createResponse] =
    propertyInsurancePolicyApi.useCreateWithProviderMutation();
  const [updateApi, updateResponse] =
    propertyInsurancePolicyApi.useUpdateWithProviderMutation();

  const entities = useMemo(() => {
    return pickBy(
      insurancePolicyState.entities,
      (v) => v?.entityType === InsurancePolicyEntityType.Property
    );
  }, [insurancePolicyState.entities]);

  const propertyInsurancePolicyState: typeof insurancePolicyState = {
    entities,
    ids: keys(entities),
    selectedId: insurancePolicyState.selectedId,
    searchCursor: insurancePolicyState.searchCursor
  };

  const createPolicyWithProviders = async (
    r: InsurancePolicyProvidersServiceRequest
  ) => {
    return await createApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Created  successfully",
            description: `policy id: ${
              res.data.policyId
            } providers: ${JSON.stringify(res.data.policyProvider)}`
          });
        } else {
          errorToast({
            title: "Failed",
            description: "Failed to create insurance policy."
          });
        }
        return res;
      });
  };

  const updatePolicyWithProviders = async (
    r: InsurancePolicyProvidersServiceRequest
  ) => {
    return await updateApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Updated successfully",
            description: `policy id: ${
              res.data.policyId
            } providers: ${JSON.stringify(res.data.policyProvider)}`
          });
        } else {
          errorToast({
            title: "Failed",
            description: "Failed to update insurance policy."
          });
        }
        return res;
      });
  };

  const upsertHandler = async (r: {
    dealId: string;
    insurancePolicy: Partial<PropertyInsurancePolicy>;
    serviceProvider: {
      domainConfigurationId: string;
      company: CompanyServiceProviderRequest;
      branch: BranchServiceProviderRequest;
      rep: RepServiceProviderRequest;
    };
  }) => {
    const { branch, rep } = r.serviceProvider;
    const branchRepRelation = `${branch.id}_${rep.id}`;
    const payload: InsurancePolicyProvidersServiceRequest = {
      dealId: r.dealId,
      insurancePolicy: r.insurancePolicy,
      serviceProvider: {
        ...r.serviceProvider,
        entityIds:
          branchRepState?.entities?.[branchRepRelation]?.dealEntities?.[
            r.dealId
          ].entityIds || []
      }
    };
    if (!r.insurancePolicy.id) {
      return await createPolicyWithProviders(payload);
    }
    return await updatePolicyWithProviders(payload);
  };

  const isSubmitting =
    (createResponse.isLoading || updateResponse.isLoading) &&
    !(createResponse.isError || updateResponse.isError);

  return {
    propertyInsurancePolicyState,
    getDealPropertiesApi,
    upsertHandler,
    isSubmitting,
    updateResponse,
    createResponse,
    getDealPropertiesApiResponse,
    selectedDeal
  };
};
