import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import TaskConfigurationTab from "./TaskConfigurationTab";

import TaskRuleTemplateTab from "./TaskRuleTemplateTab";
import TaskTemplateTab from "./TaskTemplateTab";

const TaskManagementPage = () => {
  const HEADER_HEIGHT = "40px"; //TABS HEIGHT
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy
        lazyBehavior="unmount"
      >
        <TabList>
          <Tab>Task Configuration</Tab>
          <Tab>Task Template</Tab>
          <Tab>Rule Template</Tab>
        </TabList>
        <TabPanels w="100%" p="0" h={`calc(100% - ${HEADER_HEIGHT})`}>
          <TabPanel h="100%" w="100%" p="0">
            <TaskConfigurationTab />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TaskTemplateTab />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TaskRuleTemplateTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default TaskManagementPage;
